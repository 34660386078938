/**
 * L.Draggable
 */
L.Draggable.include({

    updateMapBearing: function(mapBearing) {
        this._mapBearing = mapBearing;
    },

});
